import { template as template_4a2c99d922f34de8adc71452ccda5376 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_4a2c99d922f34de8adc71452ccda5376(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
