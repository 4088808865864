import { template as template_abe22c03fc1345c89f743bf5b4900a06 } from "@ember/template-compiler";
const FKLabel = template_abe22c03fc1345c89f743bf5b4900a06(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
