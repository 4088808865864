import { template as template_8e107ac9acbb49698c0f699662dfa8ce } from "@ember/template-compiler";
const FKText = template_8e107ac9acbb49698c0f699662dfa8ce(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
