import { template as template_c254cd9092f64f6a8541b9edf61d780f } from "@ember/template-compiler";
const FKControlMenuContainer = template_c254cd9092f64f6a8541b9edf61d780f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
