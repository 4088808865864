import { template as template_ac5972a71af14cd7965ac1b0aa151070 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_ac5972a71af14cd7965ac1b0aa151070(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
